<template>
  <div>
    <v-card class="overflow-hidden" rounded="lg">
      <v-toolbar flat>
        <v-toolbar-title class="text-wrap"> Filtri </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" class="pa-2">
            <v-select
              v-model="selectedType"
              :label="$t('payment.type') + ' ' + $tc('models.payment', 2)"
              :items="paymentTypes"
            >
              <template #selection="{ item }">
                {{ $t("options.payment.type." + item) }}
              </template>
              <template #item="{ item }">
                {{ $t("options.payment.type." + item) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" class="pa-2" v-if="!isResidenceType">
            <managed-areas-select v-model="selectedArea"> </managed-areas-select>
          </v-col>
          <v-col cols="12" class="pa-2" v-if="!isResidenceType">
            <v-select
              :label="$tc('models.course', 1)"
              return-object
              item-text="name"
              :items="courses"
              v-model="selectedCourse"
              class="text-capitalize"
              :disabled="!selectedArea"
            />
          </v-col>
          <v-col cols="12" class="pa-2" v-if="selectedType === 'event_subscription'">
            <v-select
              :label="$tc('models.event', 1)"
              return-object
              item-text="name"
              :items="events"
              v-model="selectedEvent"
              class="text-capitalize"
              :disabled="selectedCourse === null"
            />
          </v-col>
          <v-col cols="12" class="pa-2" v-if="isResidenceType">
            <managed-residences-select v-model="selectedResidence" />
          </v-col>
          <v-col class="pa-2" v-if="isResidenceType">
            <date-text-field label="Start date" v-model="from" />
          </v-col>
          <v-col class="pa-2" v-if="isResidenceType">
            <date-text-field label="End date" v-model="to" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br />
    <base-export-table
      :fields="headers"
      :items="payments"
      :loading="$apollo.queries.payments.loading"
      :sheet_name="$tc('models.payment', 2)"
      :file_name="fileName"
    >
      <template v-slot:[`type`]="{ item }">
        {{ item.type ? $t(`options.payment.type.${item.type}`) : "" }}
      </template>
      <template v-slot:[`payment_method`]="{ item }">
        {{ item.payment_method ? $t(`options.payment.payment_method.${item.payment_method}`) : "" }}
      </template>
    </base-export-table>
  </div>
</template>

<script>
import gql from "graphql-tag";
import _orderBy from "lodash/orderBy";
import _get from "lodash/get";

import moment from "moment";
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import BaseExportTable from "./BaseExportTable.vue";
import DateTextField from "../forms/inputs/DateTextField.vue";
import { dateFilter } from "../../maia/utils/table";
import { nextTick } from "vue";
import ManagedAreasSelect from "../inputs/ManagedAreasSelect.vue";

const PAYMENT_EXPORT_FRAGMENT = gql`
  fragment paymentExport on Payment {
    id
    amount
    date
    type
    payment_method
    description
    studentYear {
      id
      residence {
        id
        name
      }
      student {
        id
        cf
        address
        city
        province
        cap
        user {
          id
          name
          surname
        }
      }
    }
  }
`;

export default {
  components: { ManagedResidencesSelect, BaseExportTable, DateTextField, ManagedAreasSelect },
  name: "PaymentsExport",
  data() {
    return {
      payments: null,

      menu1: false,
      menu2: false,

      headers: [
        {
          text: this.$t("user.surname"),
          value: "studentYear.student.user.surname",
        },
        {
          text: this.$t("user.name"),
          value: "studentYear.student.user.name",
        },
        {
          text: this.$t("student.cf"),
          value: "studentYear.student.cf",
        },
        {
          text: this.$t("payment.amount"),
          value: "amount",
          type: "currency",
        },
        {
          text: this.$t("payment.date"),
          value: "date",
          type: "date",
          filter: dateFilter,
          format: ({ date }) => moment(date).format("L"),
        },
        {
          text: this.$t("payment.type"),
          value: "type",
          type: "template",
          filter: (value, search) =>
            this.$t(`options.payment.type.${value}`).toLowerCase().includes(search?.toLowerCase()),
          format: ({ type }) => this.$t(`options.payment.type.${type}`),
        },
        {
          text: this.$t("payment.payment_method"),
          value: "payment_method",
          type: "template",
          filter: (value, search) =>
            value
              ? this.$t(`options.payment.payment_method.${value}`).toLowerCase().includes(search?.toLowerCase())
              : false,
          format: ({ payment_method }) =>
            payment_method ? this.$t(`options.payment.payment_method.${payment_method}`) : "",
        },
        {
          text: this.$t("payment.description"),
          value: "description",
        },
        {
          text: this.$t("student.address"),
          value: "studentYear.student.address",
        },
        {
          text: this.$t("student.cap"),
          value: "studentYear.student.cap",
        },
        {
          text: this.$t("student.city"),
          value: "studentYear.student.city",
        },
        {
          text: this.$t("student.province"),
          value: "studentYear.student.province",
        },
      ],

      courses: [],
      events: [],

      residencePaymentTypes: ["any", "tuition", "deposit", "refund", "client_contribution"],

      selectedResidence: null,
      selectedEvent: null,
      selectedCourse: null,
      selectedArea: null,
      selectedType: "any",

      from: moment().set("day", 1).subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
      to: moment().set("day", 1).subtract(1, "years").endOf("year").format("YYYY-MM-DD"),

      paymentTypes: [
        "any",
        "tuition",
        "deposit",
        "course_enrollment",
        "event_subscription",
        "refund",
        "client_contribution",
      ],

      moment,
    };
  },

  computed: {
    fileName() {
      let name;
      if (this.isResidenceType) name = this.selectedResidence?.name;
      else if (this.isEventType) name = this.selectedEvent?.name;
      else if (this.isCourseType) name = this.selectedCourse?.name;

      return `${moment().format("YYYY-MM-DD")}_Export_Pagamenti_${name}.xlsb`;
    },

    isResidenceType() {
      return this.residencePaymentTypes.includes(this.selectedType);
    },

    isEventType() {
      return this.selectedType === "event_subscription";
    },

    isCourseType() {
      return this.selectedType === "course_enrollment";
    },

    query() {
      if (this.isResidenceType)
        return gql`
          query ResidencePaymentsExport($id: ID!, $dateRange: DateRange, $type: String) {
            residence(id: $id) {
              id
              name
              payments(date: $dateRange, type: $type) {
                ...paymentExport
              }
            }
          }
          ${PAYMENT_EXPORT_FRAGMENT}
        `;
      if (this.selectedType === "course_enrollment")
        return gql`
          query CoursePaymentsExport($id: ID!) {
            course(id: $id) {
              id
              name
              payments {
                ...paymentExport
              }
            }
          }
          ${PAYMENT_EXPORT_FRAGMENT}
        `;
      if (this.selectedType === "event_subscription")
        return gql`
          query EventPaymentsExport($id: ID!) {
            event(id: $id) {
              id
              name
              payments {
                ...paymentExport
              }
            }
          }
          ${PAYMENT_EXPORT_FRAGMENT}
        `;

      return null;
    },

    variables() {
      if (this.isResidenceType)
        return {
          id: this.selectedResidence.id,
          dateRange: {
            from: this.from,
            to: this.to,
          },
          ...(this.selectedType === "any" ? {} : { type: this.selectedType }),
        };
      if (this.selectedType === "course_enrollment") return { id: this.selectedCourse.id };
      if (this.selectedType === "event_subscription") return { id: this.selectedEvent.id };

      return {};
    },
  },
  watch: {
    selectedType() {
      this.selectedCourse = null;
      this.selectedEvent = null;
      this.from = moment().set("day", 1).subtract(1, "years").startOf("year").format("YYYY-MM-DD");
      this.to = moment().set("day", 1).subtract(1, "years").endOf("year").format("YYYY-MM-DD");
    },
    isResidenceType() {
      nextTick(() => (this.selectedResidence = null));
    },
  },
  apollo: {
    payments: {
      query() {
        return this.query;
      },
      variables() {
        return this.variables;
      },
      skip() {
        if (this.selectedType === "course_enrollment") return this.selectedCourse === null;
        if (this.selectedType === "event_subscription") return this.selectedEvent === null;
        if (this.isResidenceType) return !this.selectedResidence || !this.from || !this.to;
        return true;
      },
      update(data) {
        let path = "";
        if (this.isResidenceType) path = "residence.payments";
        if (this.selectedType == "course_enrollment") path = "course.payments";
        if (this.selectedType == "event_subscription") path = "event.payments";

        const payments = _get(data, path);
        return _orderBy(payments, ["studentYear.student.user.complete_name"]);
      },
    },
    courses: {
      query: gql`
        query AreaCoursesWithPayments($id: ID!, $has_payments: Boolean, $withEventsWithPayments: Boolean) {
          area(id: $id) {
            id
            courses(has_payments: $has_payments, withEventsWithPayments: $withEventsWithPayments) {
              id
              name
            }
          }
        }
      `,
      skip() {
        return this.isResidenceType || this.selectedArea == null;
      },
      variables() {
        return {
          id: this.selectedArea.id,
          ...(this.selectedType === "course_enrollment" ? { has_payments: true } : { withEventsWithPayments: true }),
        };
      },
      update(data) {
        return _orderBy(data.area.courses, ["name"]);
      },
    },
    events: {
      query: gql`
        query CourseEvents($id: ID!) {
          course(id: $id) {
            id
            events(has_payments: true) {
              id
              name
            }
          }
        }
      `,
      skip() {
        return this.selectedType !== "event_subscription" || this.selectedCourse == null;
      },
      variables() {
        return { id: this.selectedCourse.id };
      },
      update(data) {
        return _orderBy(data.course.events, ["name"]);
      },
    },
  },
};
</script>

<style></style>
