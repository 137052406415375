<template>
  <v-select
    :items="years"
    item-text="name"
    item-value="id"
    v-model="selectedYear"
    return-object
    :loading="loading"
    :label="$t('year.name')"
  ></v-select>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      years: [],
    };
  },
  props: ["value"],
  apollo: {
    years: {
      query: gql`
        query ClientYears {
          me {
            id
            residence {
              id
              area {
                id
                client {
                  id
                  years {
                    id
                    name
                    academicYear {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      `,
      update: (data) => data.me.residence.area.client.years,
    },
  },
  computed: {
    selectedYear: {
      get() {
        const id = this.$route.query.year_id;
        return id ? this.years.find((year) => year.id === id) : null;
      },
      set(value) {
        this.$router.replace({ query: { ...this.$route.query, year_id: value.id } });
      },
    },
    loading() {
      return this.$apollo.queries.years.loading;
    },
  },
  watch: {
    selectedYear() {
      this.$emit("input", this.selectedYear);
    },
  },
};
</script>

<style></style>
