const headerLogoHeight = 70;
const footerLogoHeight = 60;
const subLogoTextSize = 7;
const lineSpacing = 20;
const topMargin = 25;
export const docWidth = 595;
const docHeight = 842;
export const rightMargin = 40;
const footerFontSize = 12;

const footerY = footerLogoHeight + topMargin;
const headY = topMargin + headerLogoHeight + subLogoTextSize * 3 * 1.6 + lineSpacing * 2;

import jsPDF from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";

export class PDF {
  constructor(title, transcriptSettings) {
    //595 pt x 842 pt
    this.doc = new jsPDF("p", "pt", "a4");

    this.currentY = headY;

    this.fontSize = 14;
    this.setFontSize(14);

    this.doc.setProperties({
      title: title,
    });

    this.transcriptSettings = transcriptSettings;
  }

  addPage() {
    this.doc.addPage();
    this.currentY = headY;
  }

  printLogo(img, height) {
    let ratio = img.width / img.height;
    let width = parseInt(height * ratio);

    this.doc.addImage(img, "png", (docWidth - width) / 2, this.currentY, width, height);

    this.currentY += height;
  }

  printCenteredText(text) {
    if (this.currentY + this.fontSize * 1.6 > docHeight - footerY) this.addPage();
    let xOffset =
      this.doc.internal.pageSize.width / 2 - (this.doc.getStringUnitWidth(text) * this.doc.internal.getFontSize()) / 2;
    this.doc.text(text, xOffset, this.currentY);
    this.currentY += this.fontSize * 1.6;
  }

  printLineText(text) {
    this.doc.text(text, rightMargin, this.currentY);
    let count = (text.match(/\n/g) || []).length + 1;
    this.currentY += this.fontSize * 1.6 * count;
  }

  autoTable(params) {
    params = _.merge(
      {
        headStyles: { fillColor: this.doc.getTextColor() },
        startY: this.currentY,
        margin: { top: headY, bottom: footerY },
      },
      params
    );
    this.doc.autoTable(params);
    this.currentY = this.doc.autoTable.previous.finalY + lineSpacing;
  }

  setFont(font) {
    this.doc.setFont(font);
  }

  setFontType(fontType) {
    fontType;
    //this.doc.setFontStyle(fontType);
  }

  setFontSize(fontSize) {
    this.fontSize = fontSize;
    this.doc.setFontSize(fontSize);
  }

  setTextColor(r, g, b) {
    this.doc.setTextColor(r, g, b);
  }

  setTextHexColor(hex) {
    this.doc.setTextColor(hex);
  }

  async output() {
    this.printDateAndSign();
    let pageCount = this.doc.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      this.doc.setPage(i);
      await this.printHeader();
      await this.printFooter();
    }
    return this.doc.output("datauristring");
  }

  async plainOutput() {
    let pageCount = this.doc.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      this.doc.setPage(i);
      await this.printHeader();
      await this.printFooter();
    }
    return this.doc.output("datauristring");
  }

  async printHeader() {
    this.currentY = topMargin;

    this.setFontSize(subLogoTextSize);

    if (this.transcriptSettings.fill_logo_color) {
      this.doc.setFillColor(this.transcriptSettings.fill_logo_color);
      this.doc.rect(0, this.currentY, docWidth, headerLogoHeight, "F");
    }
    if (this.transcriptSettings.logo) {
      let logo = new Image();
      logo.src = this.transcriptSettings.logo.link;
      await logo.decode();
      this.printLogo(logo, headerLogoHeight);
    }

    this.currentY += lineSpacing / 2;

    let lines = this.transcriptSettings.header_text?.split("\n") ?? [];
    lines.forEach((line) => {
      this.printCenteredText(line);
    });
  }

  async printFooter() {
    if (this.transcriptSettings.footerLogo) {
      this.currentY = docHeight - footerY;
      let logo = new Image();
      logo.src = this.transcriptSettings.footerLogo.link;
      await logo.decode();
      this.printLogo(logo, footerLogoHeight);
    }
  }

  printDateAndSign() {
    this.setFontSize(footerFontSize);
    this.setFontType("normal");

    if (this.currentY + this.fontSize * 7 * 1.6 + 7 * 2 * 1.6 > docHeight - footerY) this.addPage();

    let rigth_margin_0 = this.doc.getStringUnitWidth("Data ") * footerFontSize;
    let rigth_margin_1 = this.doc.getStringUnitWidth("Il direttore") * footerFontSize;
    let rigth_margin_2 = this.doc.getStringUnitWidth("Lo studente") * footerFontSize;

    this.doc.text("Data", rightMargin, this.currentY);
    this.doc.line(rightMargin + rigth_margin_0, this.currentY, docWidth / 3, this.currentY);

    this.doc.text("Il direttore", docWidth - (rightMargin + rigth_margin_1), this.currentY);
    this.currentY += this.fontSize * 1.5 * 1.6;

    this.doc.line((docWidth * 2) / 3, this.currentY, docWidth - rightMargin, this.currentY);
    this.currentY += this.fontSize * 1.6;

    this.doc.text("Lo studente", docWidth - (rightMargin + rigth_margin_2), this.currentY);
    this.currentY += this.fontSize * 1.5 * 1.6;

    this.doc.line((docWidth * 2) / 3, this.currentY, docWidth - rightMargin, this.currentY);
    this.currentY += this.fontSize * 2 * 1.6;

    this.setFontSize(7);

    let lines = this.transcriptSettings.footer_text?.split("\n") ?? [];
    lines.forEach((line) => {
      this.printCenteredText(line.toUpperCase());
    });
  }
}
