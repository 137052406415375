<template>
  <div>
    <v-card v-if="visible" class="overflow-hidden" rounded="lg">
      <v-toolbar flat>
        <v-toolbar-title class="text-wrap"> Report </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer v-if="!searching" />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          ref="search"
          single-line
          dense
          hide-details
          clearable
          v-if="searching"
          @blur="searchBlur"
        ></v-text-field>
        <v-btn fab depressed x-small v-if="!searching" @click="startSearch" color="primary" dark>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          class="ml-1"
          fab
          depressed
          x-small
          @click="selectEnabled = !selectEnabled"
          :color="selectEnabled ? 'primary' : 'secondary'"
          dark
        >
          <v-icon>mdi-select</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-data-table
          ref="table"
          :show-select="selectEnabled"
          v-model="selected"
          :search="search"
          dense
          :headers="fields"
          :items="items"
          :items-per-page="50"
          :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
          v-bind="tableProps"
        >
          <template v-for="field in typedFields" v-slot:[`item.${field.value}`]="{ item }">
            <div v-if="field.type == 'file'" :key="'file' + field.value">
              <v-btn
                v-if="_get(item, field.value)"
                color="info"
                small
                text
                link
                target="_blank"
                :href="_get(item, field.value + '.link')"
              >
                {{ $t(`view`) }}
              </v-btn>
              <span :key="'empty' + field.value" v-else>({{ $t("empty") }})</span>
            </div>

            <span class="text-no-wrap" v-else-if="field.type == 'timestamp'" :key="'timestamp' + field.value">{{
              _get(item, field.value) && moment(_get(item, field.value)).format("lll")
            }}</span>

            <span class="text-no-wrap" v-else-if="field.type == 'date'" :key="'date' + field.value">{{
              _get(item, field.value) && moment(_get(item, field.value)).format("L")
            }}</span>

            <span class="text-no-wrap" v-else-if="field.type == 'time'" :key="'time' + field.value">{{
              moment(_get(item, field.value)).format("HH:SS")
            }}</span>

            <span class="text-no-wrap" v-else-if="field.type == 'currency'" :key="'currency' + field.value"
              >{{ Number.parseFloat(_get(item, field.value)).toFixed(2) }} €</span
            >

            <slot v-else-if="field.type == 'template'" :name="field.value" v-bind:item="item">
              <span :key="field.value" class="text-no-wrap">{{ _get(item, field.value) }}</span>
            </slot>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container>
                <slot v-bind:item="item" v-bind:headers="headers" name="expanded-item"
                  >Details on {{ item.__typename }}</slot
                >
              </v-container>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot name="actions"></slot>
        <v-btn class="ml-2" color="primary" @click="downloadTableData">Download</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="loading" class="overflow-hidden" rounded="lg">
      <v-skeleton-loader type="table" />
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import _get from "lodash/get";
import { downloadExcel } from "../../maia/excel";
import Vue from "vue";

import { updateDatatableCustomFilter } from "../../maia/utils/table";

export default {
  props: {
    items: Array,
    fields: Array,
    loading: Boolean,
    sheet_name: {
      type: String,
      default: "Table",
    },
    file_name: {
      type: String,
      default: "Export",
    },
    tableProps: { type: Object, default: () => ({}) },
  },

  watch: {
    visible() {
      if (!this.visible) return;

      this.search = "";
      this.searching = false;
      this.selected = [];
      Vue.nextTick(() => updateDatatableCustomFilter(this.$refs.table));
    },
    selectEnabled() {
      this.selected = [];
    },
  },

  data() {
    return {
      moment: moment,
      searching: false,
      search: "",
      selectEnabled: false,
      selected: [],
    };
  },
  computed: {
    visible() {
      return !this.loading && this.items != null;
    },

    typedFields() {
      return this.fields.filter((header) => header.type);
    },
  },
  methods: {
    _get: _get,

    getCsvArrays() {
      if (!this.getFormattedItems()) return "#";

      const header = this.fields.filter((header) => !header.no_export).map((header) => header.text);
      return [header, ...this.getFormattedItems()];
    },

    getFormattedItems() {
      const items = this.selectEnabled ? this.selected : this.items;
      return items.map((item) =>
        this.fields.reduce((previous, header) => {
          if (header.no_export) return previous;
          if (typeof header.format === "function") previous.push(header.format(item));
          else previous.push(_get(item, header.value));

          return previous;
        }, [])
      );
    },

    downloadTableData() {
      downloadExcel({
        sheet_name: this.sheet_name,
        file_name: `${moment().format("YYYY-MM-DD")}_Export_${this.file_name}.xlsb`,
        data: this.getCsvArrays(),
      });
    },

    startSearch() {
      this.searching = true;
      Vue.nextTick(() => this.$refs.search.$refs.input.focus());
    },

    searchBlur() {
      if (!this.search) this.searching = false;
    },
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
