<template>
  <div>
    <v-card class="overflow-hidden" rounded="lg">
      <v-toolbar flat>
        <v-toolbar-title class="text-wrap"> Filtri </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
      <v-card-text class="pt-0">
        <client-years-select @input="resetForm" v-model="selectedYear" />
        <managed-areas-select @input="resetForm" v-model="selectedArea" />
        <v-checkbox @change="events = null" v-model="selectCourse" :label="$t('export.select_course')" />
        <v-select
          :label="$t('export.selected_courses')"
          :items="courses"
          item-text="name"
          item-value="id"
          :loading="$apollo.queries.courses.loading"
          v-model="selectedCourse"
          @input="events = null"
          v-if="selectCourse"
        />
        <v-autocomplete
          chips
          deletable-chips
          small-chips
          :label="$t('export.selected_fields')"
          :items="headers"
          v-model="selectedFields"
          return-object
          @input="events = null"
          multiple
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          :loading="presencesExportLoading"
          color="primary"
          v-if="selectCourse"
          :disabled="!selectedCourse"
          @click="exportPresences"
          >Export presenze</v-btn
        >
        <v-btn
          @click="startQuery"
          :disabled="!selectedYear || !selectedArea || (selectCourse && !selectedCourse)"
          color="primary"
          >report</v-btn
        >
      </v-card-actions>
    </v-card>
    <br />
    <base-export-table
      :items="events"
      :sheet_name="$tc('models.event', 2)"
      :file_name="file_name"
      :fields="[...selectedFields, downloadHeader]"
      :loading="loading"
    >
      <template v-slot:[`context`]="{ item }">
        <span class="text-no-wrap">{{ $t(`options.event.context.${item.context}`) }}</span>
      </template>
      <template v-slot:[`type`]="{ item }">
        <span class="text-no-wrap">{{ $t(`options.event.type.${item.type}`) }}</span>
      </template>
      <template v-slot:[`mode`]="{ item }">
        <span class="text-no-wrap">{{ $t(`options.event.mode.${item.mode}`) }}</span>
      </template>
      <template v-slot:[`qualification`]="{ item }">
        <span class="text-no-wrap">{{ $t(`options.event.qualification.${item.qualification}`) }}</span>
      </template>
      <template v-slot:[`acknowledged`]="{ item }">
        <v-icon color="primary" v-if="item.acknowledged">mdi-check</v-icon>
        <v-icon color="danger" v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`download_presences`]="{ item }">
        <download-presents-button :presents_count="item.presents_count" :event_id="item.id" />
      </template>
    </base-export-table>
  </div>
</template>

<script>
import gql from "graphql-tag";

import moment from "moment";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import ManagedAreasSelect from "../inputs/ManagedAreasSelect.vue";
import BaseExportTable from "./BaseExportTable.vue";
import { ucFirst } from "../../apollo/helpers";
import { dateFilter } from "../../maia/utils/table";
import DownloadPresentsButton from "./education/DownloadPresentsButton.vue";

import constructGqlQuery from "../../maia/utils/query_construct";

export default {
  components: { ClientYearsSelect, ManagedAreasSelect, BaseExportTable, DownloadPresentsButton },
  name: "EducationExport",

  data() {
    return {
      areas: null,
      selectedArea: null,
      selectedYear: null,
      selectedFields: [],
      selectCourse: false,
      selectedCourse: null,
      moment,
      loading: false,
      events: null,
      presencesExportLoading: false,
      courses: [],
    };
  },
  apollo: {
    courses: {
      query: gql`
        query ($area_id: ID!, $year_id: ID!) {
          courses(area_id: $area_id, year_id: $year_id) {
            id
            name
          }
        }
      `,
      variables() {
        return {
          year_id: this.selectedYear.id,
          area_id: this.selectedArea.id,
        };
      },
      skip() {
        return !this.selectedYear || !this.selectedArea;
      },
    },
  },
  watch: {
    selectedFields() {
      this.events = null;
    },
  },
  mounted() {
    this.selectedFields = this.headers.slice(0, 6);
  },
  computed: {
    file_name() {
      if (!this.selectedArea || !this.selectedYear) return "-";
      return this.selectedArea.name + "_" + this.selectedYear.name;
    },
    downloadHeader() {
      return {
        text: ucFirst(this.$tc("models.presence", 2)).split(" ")[0],
        value: "download_presences",
        type: "template",
        no_export: true,
      };
    },
    headers() {
      return [
        {
          text: this.$t("course.name"),
          value: "course.name",
        },
        {
          text: this.$t("event.name"),
          value: "name",
        },
        {
          text: this.$t("event.argument"),
          value: "argument",
        },
        {
          text: this.$t("event.type"),
          value: "type",
          type: "template",
          filter: (value, search) => {
            if (!search) return true;
            return this.$t(`options.event.type.${value}`).toLowerCase().includes(search.toLowerCase());
          },
        },
        {
          text: this.$t("event.start_timestamp"),
          value: "start_timestamp",
          format: (event) => moment(event.start_timestamp).format("lll"),
          type: "timestamp",
          filter: dateFilter,
        },
        {
          text: this.$t("event.end_timestamp"),
          value: "end_timestamp",
          format: (event) => moment(event.start_timestamp).format("lll"),
          type: "timestamp",
          filter: dateFilter,
        },
        {
          text: this.$t("event.acknowledged"),
          type: "template",
          value: "acknowledged",
          format: (event) => this.$t(!!event.acknowledged.toString()),
        },
        {
          text: this.$t("event.organizer"),
          value: "name",
        },
        {
          text: this.$t("event.delegate"),
          value: "delegate.complete_name",
        },
        {
          text: this.$t("event.teacher"),
          value: "teacher",
        },
        {
          text: this.$t("event.qualification"),
          value: "qualification",
          type: "template",
        },
        {
          text: this.$t("event.mode"),
          value: "mode",
          type: "template",
        },
        {
          text: this.$t("event.room"),
          value: "room.name",
        },
        {
          text: this.$t("event.link"),
          value: "link",
        },
        {
          text: this.$t("event.context"),
          value: "context",
          type: "template",
        },
        {
          text: this.$t("event.cost"),
          value: "cost",
          type: "currency",
        },
        {
          text: this.$t("course.supplier"),
          value: "course.supplier",
        },
      ];
    },
  },
  methods: {
    courseQuery(query_fields) {
      const query = gql`
        query CourseEventsReport($course_id: ID!) {
          course(id: $course_id) {
            id
            events {
              id
              presents_count
              ${query_fields}
            }
          }
        }
      `;

      const variables = { course_id: this.selectedCourse };

      return { query, variables, callback: (data) => data.course.events };
    },

    yearAreaQuery(query_fields) {
      const query = gql`
        query AreaYearEventsReport($area_id: ID!, $year_id: ID!) {
          area(id: $area_id) {
            id
            courses(year_id: $year_id) {
              id
              events {
                id
                presents_count
                ${query_fields}
              }
            }
          }
        }
      `;

      const variables = { area_id: this.selectedArea.id, year_id: this.selectedYear.id };

      return { query, variables, callback: (data) => data.area.courses.flatMap((course) => course.events) };
    },

    startQuery() {
      const fields = this.selectedFields.map((h) => h.value);
      const query_fields = constructGqlQuery(fields);

      const { query, variables, callback } = this.selectCourse
        ? this.courseQuery(query_fields)
        : this.yearAreaQuery(query_fields);

      this.loading = true;
      this.$apollo
        .query({
          query,
          variables,
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          this.events = callback(data);
          this.loading = false;
        });
    },
    exportPresences() {
      if (!this.selectedCourse) return;

      this.presencesExportLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CoursePresencesExport($input: CoursePresencesExportInput!) {
              coursePresencesExport(input: $input)
            }
          `,
          variables: { input: { course_id: this.selectedCourse } },
        })
        .then(async ({ data }) => {
          var link = document.createElement("a");
          link.href = data.coursePresencesExport;
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent("click"));
        })
        .finally(() => (this.presencesExportLoading = false));
    },
    resetForm() {
      this.selectedCourse = null;
      this.events = null;
    },
  },
};
</script>
