import _get from "lodash/get";
import moment from "moment";

function filterFn(item, search, filter) {
  return (header) => {
    const value = _get(item, header.value);
    return header.filter ? header.filter(value, search, item) : filter(value, search, item);
  };
}

function searchTableItems(items, search, headersWithCustomFilters, headersWithoutCustomFilters, customFilter) {
  search = search?.trim() ?? "";

  return items.filter((item) => {
    // Headers with custom filters are evaluated whether or not a search term has been provided.
    // We need to match every filter to be included in the results.
    const matchesColumnFilters = headersWithCustomFilters.some(filterFn(item, search, customFilter));

    // Headers without custom filters are only filtered by the `search` property if it is defined.
    // We only need a single column to match the search term to be included in the results.
    const matchesSearchTerm = !search || headersWithoutCustomFilters.some(filterFn(item, search, customFilter));

    return matchesColumnFilters || matchesSearchTerm;
  });
}

export const dateFilter = (value, search) => {
  if (!search) return true;

  const format = value.length == 10 ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";

  const date = moment(value, format);
  if (!date.isValid()) return false;

  if (date.format("LLL").toLowerCase().includes(search.toLowerCase())) return true;

  const searchDate = moment(search, format);
  if (!searchDate.isValid()) return false;

  return searchDate.startOf("day").isSame(date.startOf("day"));
};

export const updateDatatableCustomFilter = (table) => {
  table.customFilterWithColumns = (items, search) => {
    return searchTableItems(items, search, table.headersWithCustomFilters, table.headersWithoutCustomFilters, table.customFilter);
  };
};
