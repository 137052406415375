<template>
  <admin-layout :title="$t('navigation.export')" :value="section" :drawerItems="drawerItems" routeName="Export">
    <template v-slot:meals>
      <meals-export />
    </template>
    <template v-slot:students>
      <students-export />
    </template>
    <template v-slot:payments>
      <payments-export />
    </template>
    <template v-slot:education>
      <education-export />
    </template>
    <template v-slot:graduates>
      <graduate-export />
    </template>
    <template v-slot:cineca>
      <cineca-export />
    </template>
  </admin-layout>
</template>

<script>
import EducationExport from "../components/export/EducationExport.vue";
import MealsExport from "../components/export/MealsExport.vue";
import PaymentsExport from "../components/export/PaymentsExport.vue";
import StudentsExport from "../components/export/StudentsExport.vue";
import CinecaExport from "../components/export/CinecaExport.vue";
import GraduateExport from "../components/export/GraduateExport.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";

export default {
  props: {
    section: {
      type: String,
    },
  },
  components: {
    MealsExport,
    StudentsExport,
    PaymentsExport,
    EducationExport,
    CinecaExport,
    GraduateExport,
    AdminLayout,
  },
  computed: {
    drawerItems() {
      return [
        { title: this.$tc("models.student", 2), icon: "mdi-account-school", section: "students" },
        { title: this.$t("navigation.education"), icon: "mdi-school", section: "education" },
        { title: this.$t("export.graduates"), icon: "mdi-seal-variant", section: "graduates" },
        { title: this.$tc("models.payment", 2), icon: "mdi-cash-multiple", section: "payments" },
        { title: this.$tc("models.meal", 2), icon: "mdi-silverware-fork-knife", section: "meals" },
        { title: this.$t("export.cineca"), icon: "mdi-database-import", section: "cineca" },
      ];
    },
  },
};
</script>
