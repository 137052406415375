export default function constructGqlQuery(fields) {
  const query = {};

  for (const field of fields) {
    const nestedFields = field.split(".");
    let currentLevel = query;

    for (const nestedField of nestedFields) {
      if (!currentLevel[nestedField]) {
        currentLevel[nestedField] = {};
      }

      currentLevel = currentLevel[nestedField];
    }
  }
  return buildQuery(query);
}

function buildQuery(query) {
  let gqlQuery = "id\n";

  for (const field in query) {
    gqlQuery += `\t${field}`;

    if (Object.keys(query[field]).length > 0) {
      gqlQuery += " {\n" + buildQuery(query[field]) + "\t}\n";
    } else {
      gqlQuery += "\n";
    }
  }

  gqlQuery += "";

  return gqlQuery;
}
