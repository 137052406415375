import XLSX from "xlsx";

export const downloadExcel = ({ sheet_name, file_name, data }) => {
  var ws_name = sheet_name;
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.aoa_to_sheet(data);
  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, file_name);
};
