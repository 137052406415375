<template>
  <div>
    <meals-recap-card root="residence" />
    <br />
    <meals-recap-card root="user" />
  </div>
</template>

<script>
import MealsRecapCard from "./MealsRecapCard.vue";
export default {
  components: { MealsRecapCard },
};
</script>

<style>
</style>