<template>
  <div>
    <v-card class="overflow-hidden" rounded="lg">
      <v-toolbar flat>
        <v-toolbar-title class="text-wrap"> Filtri </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" class="pa-2">
            <v-autocomplete
              :items="items"
              :item-text="(item) => `${item.complete_name || item.name}`"
              item-value="id"
              v-model="selectedItem"
              return-object
            >
              <template #label>
                <!-- HTML that describe how select should render selected items -->
                <span class="text-capitalize">{{ $tc(`models.${root}`, 1) }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <!-- HTML that describe how select should render selected items -->
                {{ item.name }} {{ item.surname || "" }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pa-2">
            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="moment(mealsRecapInput.start_date).format('ll')"
                  label="Start date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="mealsRecapInput.start_date" @input="menu1 = false"></v-date-picker> </v-menu
          ></v-col>
          <v-col class="pa-2">
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="moment(mealsRecapInput.end_date).format('ll')"
                  label="End date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="mealsRecapInput.end_date" @input="menu2 = false"></v-date-picker> </v-menu
          ></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="mealsRecap" class="overflow-hidden mt-4" rounded="lg">
      <v-toolbar flat>
        <v-toolbar-title class="text-wrap"> Report </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-data-table dense :headers="headers" :items="formattedItems" :items-per-page="40" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="downloadCsv">Download</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { MANAGED_RESIDENCES_MEALS, MANAGED_USERS_MEALS } from "../../graphql/queries";

import moment from "moment";
// import _pickBy from "lodash/pickBy";
import XLSX from "xlsx";
import _get from "lodash/get";

export default {
  name: "MealRecapCard",
  data() {
    return {
      mealsRecap: null,

      menu1: false,
      menu2: false,

      headers: [
        {
          text: "Data",
          value: "date",
        },
        {
          text: "Totale colazioni",
          value: "breakfast_total",
        },
        {
          text: "Totale pranzi",
          value: "launch_total",
        },
        {
          text: "Totale cene",
          value: "dinner_total",
        },
      ],

      selectedItem: null,
      mealsRecapInput: {
        start_date: moment().subtract(1, "months").format("YYYY-MM-01"),
        end_date: moment().subtract(1, "months").format("YYYY-MM-") + moment().subtract(1, "months").daysInMonth(),
      },
    };
  },
  computed: {
    csvArrays() {
      if (!this.formattedItems) return "#";

      const data = this.formattedItems.map((meal) =>
        this.headers.reduce((previous, header) => {
          previous.push(_get(meal, header.value));
          return previous;
        }, [])
      );
      const header = this.headers.map((header) => header.text);
      return [header, ...data];
    },

    dateRange() {
      let dateArray = new Array();
      let currentDate = moment(this.mealsRecapInput.start_date);
      let endDate = moment(this.mealsRecapInput.end_date);
      while (currentDate <= endDate) {
        dateArray.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "days");
      }

      dateArray = dateArray.map((date) => ({
        date: date,
        breakfast_total: 0,
        launch_total: 0,
        dinner_total: 0,
      }));
      return dateArray;
    },

    formattedItems() {
      if (!this.mealsRecap) return null;
      let formattedMeals = [...this.mealsRecap];
      this.dateRange.forEach((date) => {
        if (!this.mealsRecap.some((meal) => meal.date == date.date)) {
          formattedMeals.push(date);
        }
      });
      formattedMeals.sort((a, b) => moment(a.date).diff(moment(b.date)));

      return formattedMeals.map((meal) => ({
        ...meal,
        date: moment(meal.date).format("ll"),
      }));
    },
  },
  props: {
    root: {
      type: String,
      required: true,
    },
  },
  apollo: {
    items: {
      query() {
        return this.root == "user" ? MANAGED_USERS_MEALS : MANAGED_RESIDENCES_MEALS;
      },
      update(data) {
        return this.root == "user"
          ? data.me.managedResidencesMeals.reduce((acc, residence) => [...acc, ...residence.users], [])
          : data.me.managedResidencesMeals;
      },
    },

    mealsRecap: {
      fetchPolicy: "network-only",
      query() {
        return gql`
        query MealsRecap($id: ID!, $input: MealsRecapInput!) {
          ${this.root}(id: $id) {
            id
            name
            mealsRecap(input: $input) {
              date
              breakfast_total
              launch_total
              dinner_total
            }
          }
        }
      `;
      },
      update(data) {
        return data[this.root].mealsRecap;
      },
      variables() {
        return {
          id: this.selectedItem.id,
          input: { ...this.mealsRecapInput },
        };
      },
      skip() {
        return !this.selectedItem || !this.mealsRecapInput.start_date || !this.mealsRecapInput.end_date;
      },
    },
  },
  methods: {
    downloadCsv() {
      var ws_name = this.selectedItem.complete_name ?? this.selectedItem.name;
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(this.csvArrays);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, `${moment().format("YYYY-MM-DD")}_Export_Pasti_${this.selectedItem.complete_name ?? this.selectedItem.name}.xlsb`);
    },
    moment,
  },
};
</script>

<style></style>
