<template>
  <v-container class="pl-16 h-100">
    <v-navigation-drawer color="primary" dark v-model="drawer" absolute permanent :mini-variant="mini" class="drawer">
      <v-list dense>
        <v-list-item @click.stop="toggleMini">
          <v-list-item-icon>
            <v-icon>mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
          v-for="item in drawerItems"
          :key="item.title"
          link
          @click="mini = true"
          :to="{ name: routeName, params: { section: item.section } }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ ucFirst(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-window class="h-100" vertical continuous :value="value" @input="$emit('input', $event)">
      <v-window-item v-for="item in drawerItems" :key="item.title" :value="item.section">
        <slot :name="item.section"></slot>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import { ucFirst } from "../apollo/helpers";

export default {
  name: "Layout",
  props: {
    title: String,
    drawerItems: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
    },
    routeName: {
      type: String,
    },
  },
  data() {
    return {
      mini: true,
      drawer: true,
    };
  },
  methods: {
    toggleMini() {
      this.mini = !this.mini;
    },
    ucFirst,
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  z-index: 100;
}
</style>
