<template>
  <v-card class="overflow-hidden" rounded="lg">
    <v-toolbar flat>
      <v-toolbar-title class="text-wrap"> Export CINECA </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-card-text class="pt-0">
      <managed-residences-select v-model="selectedResidence" />
      <client-years-select v-model="selectedYear" />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="!selectedResidence || !selectedYear" :loading="loading" @click="downloadEducationAgreements" color="primary"
        >Export patti formativi</v-btn
      >
      <v-btn :disabled="!selectedResidence || !selectedYear" :loading="loading" @click="downloadTranscripts" color="primary">Export libretti</v-btn>
      <v-btn :disabled="!selectedResidence || !selectedYear" :loading="loading" @click="downloadStudentYears" color="primary">Export studenti</v-btn>
      <v-btn :disabled="!selectedResidence || !selectedYear" :loading="loading" @click="downloadActivities" color="primary">Export attività</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import gql from "graphql-tag";

export default {
  components: { ManagedResidencesSelect, ClientYearsSelect },
  name: "CinecaExport",
  data() {
    return {
      selectedResidence: null,
      selectedYear: null,
      loading: false,
    };
  },
  methods: {
    downloadActivities() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation PresencesExport($input: PresencesExportInput!) {
              presencesExport(input: $input)
            }
          `,
          variables: { input: { residence_id: this.selectedResidence.id, year_id: this.selectedYear.id } },
        })
        .then(async ({ data }) => {
          var link = document.createElement("a");
          link.href = data.presencesExport;
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent("click"));
        })
        .finally(() => (this.loading = false));
    },
    downloadStudentYears() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation StudentYearsExport($input: StudentYearsExportInput!) {
              studentYearsExport(input: $input)
            }
          `,
          variables: { input: { residence_id: this.selectedResidence.id, year_id: this.selectedYear.id } },
        })
        .then(async ({ data }) => {
          var link = document.createElement("a");
          link.href = data.studentYearsExport;
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent("click"));
        })
        .finally(() => (this.loading = false));
    },
    downloadTranscripts() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation TranscriptsExport($input: TranscriptsExportInput!) {
              transcriptsExport(input: $input)
            }
          `,
          variables: { input: { residence_id: this.selectedResidence.id, year_id: this.selectedYear.id } },
        })
        .then(async ({ data }) => {
          var link = document.createElement("a");
          link.href = data.transcriptsExport;
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent("click"));
        })
        .finally(() => (this.loading = false));
    },
    downloadEducationAgreements() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation EducationAgreementsExport($input: EducationAgreementsExportInput!) {
              educationAgreementsExport(input: $input)
            }
          `,
          variables: { input: { residence_id: this.selectedResidence.id, year_id: this.selectedYear.id } },
        })
        .then(async ({ data }) => {
          var link = document.createElement("a");
          link.href = data.educationAgreementsExport;
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent("click"));
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
