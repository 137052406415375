<template>
  <div>
    <v-card class="overflow-hidden" rounded="lg">
      <v-toolbar flat>
        <v-toolbar-title class="text-wrap"> Filtri </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-toolbar>
      <v-card-text class="pt-0">
        <managed-residences-select @input="items = null" v-model="selectedResidence" />
        <client-years-select @input="items = null" v-model="selectedYear" />
        <v-autocomplete
          chips
          deletable-chips
          small-chips
          :label="$t('export.selected_fields')"
          :items="headers"
          v-model="selectedFields"
          return-object
          multiple
        />
        <!-- <v-row>
          <v-col cols="3" v-for="(header, i) in headers" :key="i">
            <v-checkbox v-model="selectedFields" :label="header.text" :value="header" />
          </v-col>
        </v-row> -->
      </v-card-text>
      <v-card-actions class="d-flex justify-end"
        ><v-btn @click="startQuery" :disabled="!selectedYear || !selectedResidence" color="primary"
          >report</v-btn
        ></v-card-actions
      >
    </v-card>
    <br />
    <base-export-table
      :loading="loading"
      :fields="selectedFields"
      :items="items"
      :sheet_name="$tc('models.student', 2)"
      :file_name="fileName"
    >
      <template v-slot:[`primaryCareerYear.arithmetic_average`]="{ item }">
        {{ item.primaryCareerYear && item.primaryCareerYear.arithmetic_average.toFixed(2) }}
      </template>
      <template v-slot:[`primaryCareerYear.weighted_average`]="{ item }">
        {{ item.primaryCareerYear && item.primaryCareerYear.weighted_average.toFixed(2) }}
      </template>
      <template v-slot:[`primaryCareerYear.career.arithmetic_average`]="{ item }">
        {{ item.primaryCareerYear && item.primaryCareerYear.career.arithmetic_average.toFixed(2) }}
      </template>
      <template v-slot:[`primaryCareerYear.career.weighted_average`]="{ item }">
        {{ item.primaryCareerYear && item.primaryCareerYear.career.weighted_average.toFixed(2) }}
      </template>
      <template v-slot:[`type`]="{ item }">
        {{ $t(`options.studentYear.type.${item.type}`) }}
      </template>
      <template v-slot:[`student.id_card_type`]="{ item }">
        {{ $t(`options.student.id_card_type.${item.student.id_card_type}`) }}
      </template>
    </base-export-table>
  </div>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";

import constructGqlQuery from "../../maia/utils/query_construct";
import BaseExportTable from "./BaseExportTable.vue";
import { dateFilter } from "../../maia/utils/table";

export default {
  components: { ManagedResidencesSelect, ClientYearsSelect, BaseExportTable },
  name: "StudentsExport",
  data() {
    return {
      items: null,
      selectedResidence: null,
      selectedYear: null,
      selectedFields: [],
      loading: false,
    };
  },
  mounted() {
    this.selectedFields = this.headers.slice(0, 6);
  },
  watch: {
    selectedFields() {
      this.items = null;
    },
  },
  computed: {
    fileName() {
      return `${moment().format("YYYY-MM-DD")}_Export_Studenti_${this.selectedResidence?.name}.xlsb`;
    },
    headers() {
      return [
        {
          text: this.$t("user.surname"),
          value: "student.user.surname",
        },
        {
          text: this.$t("user.name"),
          value: "student.user.name",
        },
        {
          text: this.$t("user.phone"),
          value: "student.user.phone",
        },
        {
          text: this.$t("user.email"),
          value: "student.user.email",
        },
        {
          text: this.$t("student.cf"),
          value: "student.cf",
        },
        {
          text: this.$t("student.birthday"),
          value: "student.birthday",
          type: "date",
          format: (studentYear) => studentYear.student.birthday && moment(studentYear.student.birthday).format("L"),
          filter: dateFilter,
        },
        {
          text: this.$t("student.country_of_birth"),
          value: "student.country_of_birth",
        },
        {
          text: this.$t("student.born_in"),
          value: "student.born_in",
        },
        {
          text: this.$t("student.born_in_province"),
          value: "student.born_in_province",
        },
        {
          text: this.$t("student.address"),
          value: "student.address",
        },
        {
          text: this.$t("student.civic_number"),
          value: "student.civic_number",
        },
        {
          text: this.$t("student.city"),
          value: "student.city",
        },
        {
          text: this.$t("student.province"),
          value: "student.province",
        },
        {
          text: this.$t("student.cap"),
          value: "student.cap",
        },
        {
          text: this.$t("student.nationality"),
          value: "student.nationality",
        },
        {
          text: this.$t("student.other_nationality"),
          value: "student.other_nationality",
        },
        {
          text: this.$t("student.id_card_type"),
          value: "student.id_card_type",
          type: "template",
          format: (studentYear) => this.$t(`options.student.id_card_type.${studentYear.student.id_card_type}`),
          filter: (value, search) =>
            this.$t(`options.student.id_card_type.${value}`).toLowerCase().includes(search?.toLowerCase()),
        },
        {
          text: this.$t("student.id_card_number"),
          value: "student.id_card_number",
        },
        {
          text: this.$t("student.id_card_expiration_date"),
          value: "student.id_card_expiration_date",
        },
        {
          text: this.$t("studentYear.type"),
          value: "type",
          type: "template",
          format: (studentYear) => this.$t(`options.studentYear.type.${studentYear.type}`),
          filter: (value, search) =>
            this.$t(`options.studentYear.type.${value}`).toLowerCase().includes(search?.toLowerCase()),
        },
        {
          text: this.$t("studyClass.name"),
          value: "primaryCareerYear.career.studyClass.name",
        },
        {
          text: this.$t("studyClass.code"),
          value: "primaryCareerYear.career.studyClass.code",
        },
        {
          text: this.$t("career.badge_number"),
          value: "primaryCareerYear.career.badge_number",
        },
        {
          text: this.$t("classYear.name"),
          value: "primaryCareerYear.classYear.name",
        },
        {
          text: this.$t("careerYear.arithmetic_average"),
          value: "primaryCareerYear.arithmetic_average",
          type: "template",
          format: (studentYear) => studentYear.primaryCareerYear?.arithmetic_average.toFixed(2),
        },
        {
          text: this.$t("careerYear.weighted_average"),
          value: "primaryCareerYear.weighted_average",
          type: "template",
          format: (studentYear) => studentYear.primaryCareerYear?.weighted_average.toFixed(2),
        },
        {
          text: this.$t("career.arithmetic_average"),
          value: "primaryCareerYear.career.arithmetic_average",
          type: "template",
          format: (studentYear) => studentYear.primaryCareerYear?.career.arithmetic_average.toFixed(2),
        },
        {
          text: this.$t("career.weighted_average"),
          value: "primaryCareerYear.career.weighted_average",
          type: "template",
          format: (studentYear) => studentYear.primaryCareerYear?.career.weighted_average.toFixed(2),
        },
        {
          text: this.$t("gradeReference.grade_reference"),
          value: "primaryCareerYear.gradeReference.grade_reference",
        },
        {
          text: this.$t("careerYear.cfu"),
          value: "primaryCareerYear.cfu",
        },
        {
          text: this.$t("studentYear.total_presences"),
          value: "total_presences",
        },
        {
          text: this.$t("studentYear.residence_start"),
          value: "residence_start",
          type: "date",
          format: ({ residence_start }) => residence_start && moment(residence_start).format("L"),
          filter: dateFilter,
        },
        {
          text: this.$t("studentYear.residence_end"),
          value: "residence_end",
          type: "date",
          format: ({ residence_end }) => residence_end && moment(residence_end).format("L"),
          filter: dateFilter,
        },
        {
          text: this.$t("studentYear.residence_time"),
          value: "residence_time",
        },
        {
          text: this.$t("studentYear.residence_break_start"),
          value: "residence_break_start",
          type: "date",
          format: ({ residence_break_start }) => residence_break_start && moment(residence_break_start).format("L"),
          filter: dateFilter,
        },
        {
          text: this.$t("studentYear.residence_break_end"),
          value: "residence_break_end",
          type: "date",
          format: ({ residence_break_end }) => residence_break_end && moment(residence_break_end).format("L"),
          filter: dateFilter,
        },
        {
          text: this.$t("studentYear.residence_break_time"),
          value: "residence_break_time",
        },
        {
          text: this.$t("studentYear.college_year"),
          value: "college_year",
        },
        {
          text: this.$t("tuitionFormula.name"),
          value: "tuitionFormula.name",
        },
        {
          text: this.$t("studentYear.isee"),
          value: "isee",
          type: "currency",
          format: (studentYear) => studentYear.isee?.toFixed(2),
        },
        {
          text: this.$t("studentYear.contract_time"),
          value: "contract_time",
        },
        {
          text: this.$t("studentYear.residence_abroad_time"),
          value: "residence_abroad_time",
        },
        {
          text: this.$t("studentYear.residence_time_difference"),
          value: "residence_time_difference",
        },
        {
          text: this.$t("chamber.name"),
          value: "chamber.name",
        },
        {
          text: this.$t("studentYear.tuition"),
          value: "tuition",
          type: "currency",
          format: (studentYear) => studentYear.tuition?.toFixed(2),
        },
      ];
    },
  },

  methods: {
    moment,
    startQuery() {
      const fields = this.selectedFields.map((h) => h.value);

      const query_fields = constructGqlQuery(fields);

      const query = gql`
        query StudentYearReport($year_id: ID, $residence_id: ID!) {
        residence(id: $residence_id) {
          id
          studentYears(year_id: $year_id) {
            ${query_fields}
          }
        }
        }`;

      this.loading = true;
      this.$apollo
        .query({
          query,
          variables: {
            year_id: this.selectedYear.id,
            residence_id: this.selectedResidence.id,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          this.items = data.residence.studentYears.map((studentYear) => ({
            ...studentYear,
            total_presences: studentYear.total_presences / 3600,
          }));
          this.loading = false;
        });
    },
  },
};
</script>
