<template>
  <base-export-table
    :items="graduates"
    :sheet_name="$tc('models.event', 2)"
    :file_name="fileName"
    :fields="headers"
    :loading="$apollo.queries.graduates.loading"
    :table-props="tableProps"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td class="pa-0" :colspan="headers.length">
        <v-list dense>
          <div class="pl-2" v-for="degree in item.degrees" :key="degree.id">
            <v-list-item class="transparent">
              <i18n path="export.degree_description">
                <template #name>
                  <span class="font-weight-bold">{{ degree.name }}</span>
                </template>
                <template #date>
                  <span class="font-weight-bold">{{ moment(degree.date).format("L") }}</span>
                </template>
                <template #grade>
                  <span class="font-weight-bold">{{ degree.grade }}</span>
                </template>
                <template #place>
                  <span class="font-weight-bold">{{ degree.place }}</span>
                </template>
              </i18n>
            </v-list-item>
            <v-divider v-if="degree != item.degrees.at(-1)"></v-divider>
          </div>
        </v-list>
      </td>
    </template>
  </base-export-table>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import { dateFilter } from "../../maia/utils/table";
import BaseExportTable from "./BaseExportTable.vue";

export const query = gql`
  query Graduates {
    me {
      id
      client {
        id
        students(hasDegrees: { column: "type", value: "degree" }) {
          id
          user {
            id
            name
            surname
            email
            phone
          }
          degrees(where: { column: TYPE, value: "degree" }) {
            id
            type
            name
            place
            date
            grade
          }
        }
      }
    }
  }
`;

export default {
  name: "GraduatesExport",
  components: { BaseExportTable },
  computed: {
    fileName() {
      return `${moment().format("YYYY-MM-DD")}_Export_Laureati.xlsb`;
    },
  },
  data() {
    return {
      graduates: null,
      moment,
      tableProps: {
        "show-expand": true,
      },
      headers: [
        {
          text: this.$t("user.surname"),
          value: "user.surname",
        },
        {
          text: this.$t("user.name"),
          value: "user.name",
        },
        {
          text: this.$t("user.email"),
          value: "user.email",
        },
        {
          text: this.$t("user.phone"),
          value: "user.phone",
        },
        {
          text: this.$t("degree.date"),
          value: "degrees[0].date",
          type: "date",
          format: (graduated) => moment(graduated.degrees[0].date).format("L"),
          filter: dateFilter,
        },
        {
          text: this.$t("degree.name"),
          value: "degrees[0].name",
        },
        {
          text: this.$t("degree.place"),
          value: "degrees[0].place",
        },
        {
          text: this.$t("degree.grade"),
          value: "degrees[0].grade",
        },
      ],
    };
  },
  apollo: {
    graduates: {
      query,
      update(data) {
        return data.me.client.students;
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
