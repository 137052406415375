<template>
  <div>
    <v-btn :loading="loading" small elevation="0" color="primary" @click="downloadPresents">
      {{ $t("download") }} <span v-if="presents_count != null">({{ presents_count }})</span>
    </v-btn>
    <file-preview v-if="pdfUri" pdf v-model="dialog" :title="event?.name" :file_name="pdfName" :src="pdfUri" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";

import { PDF, docWidth, rightMargin } from "../../../maia/pdf";
import FilePreview from "../../base/FilePreview.vue";

const EVENT_PRESENTS_QUERY = gql`
  query EventPresents($event_id: ID!) {
    event(id: $event_id) {
      id
      name
      argument
      type
      start_timestamp
      end_timestamp
      teacher
      qualification
      context
      room {
        id
        name
      }
      mode
      course {
        id
        name
        year {
          id
          name
        }
        area {
          id
          client {
            id
            name
            transcriptSettings {
              header_text
              footer_text
              fill_logo_color
              logo {
                id
                name
                link
              }
              footerLogo {
                id
                name
                link
              }
            }
          }
        }
      }
      presents {
        id
        student {
          id
          user {
            id
            name
            surname
          }
        }
        residence {
          id
          name
        }
      }
    }
  }
`;

export default {
  name: "DownloadPresentsButton",
  components: { FilePreview },
  props: {
    event_id: {
      type: [String, Number],
      required: true,
    },
    presents_count: Number,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      pdfUri: null,
      event: null,
    };
  },
  computed: {
    pdfName() {
      return `${this.event?.course.name}_${this.event?.name}.pdf`;
    },
  },
  methods: {
    createPdfUriFromEventData(event) {
      let tableArray = [];

      //595 pt x 842 pt
      var doc = new PDF(
        `${event.course.name}-${event.name}-${moment(event.start_timestamp).format("YYYY-MM-DD")}`,
        event.course.area.client.transcriptSettings
      );

      doc.setFont("helvetica");
      doc.setFontType("bold");

      const main_color = event.course.area.client.fill_logo_color ?? "#145ba0";
      doc.setTextHexColor(main_color);

      doc.setFontSize(16);
      doc.printCenteredText("REGISTRO PRESENZE");

      doc.setFontSize(20);
      doc.printCenteredText(event.name.toUpperCase());

      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFontType("normal");
      // doc.printCenteredText(`Collegio Mazza - Residenza ${studentYear.residence.name}`);
      doc.printCenteredText(`Anno accademico ${event.course.year.name}`);

      event.presents.forEach(({ student, residence }) => {
        tableArray.push([`${student.user.surname} ${student.user.name}`, residence?.name]);
      });

      doc.setFontSize(12);
      doc.setFontType("normal");

      doc.printLineText("");

      doc.printLineText("Riepilogo evento:");

      doc.autoTable({
        head: [
          [
            this.$t("course.name"),
            this.$t("event.name"),
            this.$t("event.argument"),
            this.$t("event.type"),
            this.$t("event.date"),
          ],
        ],
        body: [
          [
            event.course.name,
            event.name,
            event.argument,
            this.$t(`options.event.type.${event.type}`),
            `${moment(event.start_timestamp).format("lll")} - ${moment(event.end_timestamp).format("HH:mm")}`,
          ],
        ],
        headStyles: { halign: "center", fillColor: main_color },
        columnStyles: {
          0: { halign: "center" },
          1: { halign: "center" },
          2: { halign: "center" },
          3: { halign: "center" },
          4: { halign: "center" },
        },
        styles: {
          fontSize: 8,
        },
      });

      doc.autoTable({
        head: [
          [
            this.$t("event.teacher"),
            this.$t("event.qualification"),
            this.$t("event.context"),
            this.$t("room.name"),
            this.$t("event.mode"),
          ],
        ],
        body: [
          [
            event.teacher,
            this.$t(`options.event.qualification.${event.qualification}`),
            this.$t(`options.event.context.${event.context}`),
            event.room?.name,
            this.$t(`options.event.mode.${event.mode}`),
          ],
        ],
        headStyles: { halign: "center", fillColor: main_color },
        columnStyles: {
          0: { halign: "center" },
          1: { halign: "center" },
          2: { halign: "center" },
          3: { halign: "center" },
          4: { halign: "center" },
        },
        styles: {
          fontSize: 8,
        },
      });

      doc.printLineText("");
      doc.printLineText("Studenti presenti:");

      doc.autoTable({
        head: [[this.$t("student.name"), this.$t("residence.name")]],
        body: tableArray,
        headStyles: { halign: "center", fontSize: 8, fillColor: main_color },
        columnStyles: {
          0: { cellWidth: (docWidth - 2 * rightMargin) / 2, halign: "center" },
          1: { halign: "center" },
        },
      });

      //doc.printFooter();

      return doc.plainOutput();
    },

    downloadPresents() {
      this.loading = true;
      this.$apollo
        .query({
          query: EVENT_PRESENTS_QUERY,
          variables: { event_id: this.event_id },
        })
        .then(async ({ data }) => {
          this.event = data.event;
          this.pdfUri = await this.createPdfUriFromEventData(data.event);
          this.loading = false;
          this.dialog = true;
        });
    },
  },
};
</script>
